/* eslint-disable react/prop-types */

import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import BlogArticle from '../components/SingleBlog/BlogArticle';
import SEO from '../components/seo';

const BlogPage = ({ data, pageContext }) => {
  const { metadata, localizations } = data.strapiBlogs;
  const global = data.strapiGlobal;
  return (
    <>
      <SEO seo={metadata} global={global} />
      <Layout global={global} pageContext={{ ...pageContext, localizations }}>
        <BlogArticle data={data.strapiBlogs} />
      </Layout>
    </>
  );
};

export default BlogPage;

export const query = graphql`
  fragment globalBlogData on StrapiGlobal {
    footer {
      ETFDepartments {
        title
        id
      }
      ETFDepartmentLinks {
        url
        title
        id
      }
      externLinks {
        id
        title
        url
      }
      address {
        title
        Description
        description
      }
      contact {
        Fax
        Email
        Tel
        title
      }
      copyright {
        title
      }
      Social {
        id
        Link
      }
    }

    id
    metadata {
      metaDescription
      metaTitle
    }
    navbar {
      mainLinks {
        id
        url
        title
      }
      secondLinks {
        id
        title
        url
      }
      ETFDepartment {
        url
        title
      }
    }
  }

  query blogPageQuery($id: String!, $locale: String!) {
    strapiGlobal(locale: { eq: $locale }) {
      ...GlobalData
    }
    strapiBlogs(id: { eq: $id }) {
      content
      date
      description
      metadata {
        metaDescription
        metaTitle
      }
      image {
        url
      }
      slug
      title
      localizations {
        id
        locale
      }
    }
  }
`;
