import React from 'react';
import ReactMarkdown from 'react-markdown';
import Moment from 'react-moment';
import PropTypes from 'prop-types';

import './singleBlog.scss';

const BlogArticle = (props) => (
  <div>
    <div className="main-wrapper col-lg-7 col-12 mx-auto">
      <div className="title-container mb-5 mt-5 lg:mt-0 container ms-lg-2">
        <h4 className="title-text">{props.data.title}</h4>
        <p className="title-description mt-4">
          <Moment format="DD.MM.YYYY">{props.data.date}</Moment>
        </p>
      </div>
      <div className="content-wrapper container">
        <ReactMarkdown className="content-text">
          {props.data.content}
        </ReactMarkdown>
      </div>
    </div>
  </div>
);

BlogArticle.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default BlogArticle;
